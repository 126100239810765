"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayNowV2 = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var StripeContext_1 = require("../StripeContext");
var styles = __importStar(require("./PayNow.css"));
var AcceptFlowContextProvider_1 = require("@CommonFrontend/AcceptFlow/AcceptFlowContextProvider");
var Utils_1 = require("../Utils");
var PayNowV2 = function (_a) {
    var payNowConfiguration = _a.payNowConfiguration, _b = _a.shouldCollectBillingDetails, shouldCollectBillingDetails = _b === void 0 ? false : _b;
    var isPreviewAccept = (0, AcceptFlowContextProvider_1.useAcceptFlowContext)().isPreviewAccept;
    var payNowAmount = payNowConfiguration.payNowAmount, amountFormatted = payNowConfiguration.amountFormatted, isOptionalPayment = payNowConfiguration.isOptionalPayment, isPartialPayment = payNowConfiguration.isPartialPayment, currencyCode = payNowConfiguration.currencyCode, partialPaymentPercentage = payNowConfiguration.partialPaymentPercentage;
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
            react_1.default.createElement(kaleidoscope_1.Heading, { level: "3", align: "center" }, "Payment"),
            react_1.default.createElement(kaleidoscope_1.Text, { align: "center", element: "div" }, "Enter your payment details below. You will not be charged until the page is accepted by all required accepters."),
            isPreviewAccept && (react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Preview mode", alignment: kaleidoscope_1.AlertAlignment.Center, className: styles.previewAlert },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", align: "center" }, "This is preview mode, please don't enter any real credit card info."))),
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "end" },
                react_1.default.createElement(kaleidoscope_1.Stack, null,
                    isOptionalPayment && react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" }, "Optional payment"),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "l", strong: true }, "Total")),
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.totalRightSide },
                    isPartialPayment && (react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" },
                        "Part payment ",
                        partialPaymentPercentage ? "(".concat(partialPaymentPercentage, "%)") : "")),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "l", strong: true },
                        amountFormatted,
                        " ",
                        currencyCode))),
            react_1.default.createElement(StripeElements, { payNowAmount: payNowAmount, currencyCode: currencyCode, shouldCollectBillingDetails: shouldCollectBillingDetails }))));
};
exports.PayNowV2 = PayNowV2;
var StripeElements = function (_a) {
    var payNowAmount = _a.payNowAmount, currencyCode = _a.currencyCode, shouldCollectBillingDetails = _a.shouldCollectBillingDetails;
    var isPreviewAccept = (0, AcceptFlowContextProvider_1.useAcceptFlowContext)().isPreviewAccept;
    var _b = (0, StripeContext_1.useStripeEnabledContext)(), stripePromise = _b.stripePromise, elementsRef = _b.elementsRef;
    var paymentElementRef = (0, react_1.useRef)(null);
    var _c = __read((0, react_1.useState)(false), 2), readyToView = _c[0], setReadyToView = _c[1];
    var formContext = (0, react_1.useContext)(kaleidoscope_1.FormContext);
    (0, react_1.useEffect)(function () {
        var paymentElement;
        new Promise(function () { return __awaiter(void 0, void 0, void 0, function () {
            var stripe, elements;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!paymentElementRef.current) {
                            return [2];
                        }
                        return [4, stripePromise];
                    case 1:
                        stripe = _a.sent();
                        elements = stripe.elements({
                            mode: "setup",
                            currency: currencyCode.toLowerCase(),
                            paymentMethodOptions: {
                                us_bank_account: {
                                    verification_method: "instant",
                                },
                            },
                        });
                        paymentElement = elements.create("payment", {
                            fields: {
                                billingDetails: shouldCollectBillingDetails
                                    ? {
                                        email: "never",
                                        name: "never",
                                    }
                                    : undefined,
                            },
                        });
                        paymentElement.mount(paymentElementRef.current);
                        paymentElement.on("ready", function () {
                            setReadyToView(true);
                        });
                        if (!isPreviewAccept) {
                            elementsRef.current = elements;
                        }
                        return [2];
                }
            });
        }); });
        return function () {
            elementsRef.current = undefined;
            paymentElement === null || paymentElement === void 0 ? void 0 : paymentElement.unmount();
        };
    }, [payNowAmount, currencyCode, shouldCollectBillingDetails, isPreviewAccept, stripePromise, elementsRef]);
    return (react_1.default.createElement("div", null,
        !readyToView && react_1.default.createElement(kaleidoscope_1.Spinner, null),
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "l", className: styles.payNowFormWrapper({ readyToView: readyToView }) },
            shouldCollectBillingDetails && (react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s", className: styles.billingDetails },
                react_1.default.createElement(kaleidoscope_1.FormTextInput, { className: Utils_1.acceptFormFocusableClassname, name: "name", label: "Name", placeholder: "Firstname Lastname", validate: function (value) {
                        return value ? undefined : "Name cannot be empty";
                    }, size: kaleidoscope_1.TextInputSize.Medium }),
                react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "name", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }),
                react_1.default.createElement(kaleidoscope_1.FormTextInput, { className: Utils_1.acceptFormFocusableClassname, name: "email", label: "Email", placeholder: "Email", type: kaleidoscope_1.TextInputType.Email, validate: function (value) {
                        return value ? undefined : "Email cannot be empty";
                    }, size: kaleidoscope_1.TextInputSize.Medium }),
                react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "email", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }))),
            react_1.default.createElement("div", { ref: paymentElementRef }))));
};
