import "../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css\",\"source\":\"Ll8xOXpydnJ0MCB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xOXpydnJ0MSB7CiAgbWFyZ2luLWxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoLTUwJSk7CiAgd2lkdGg6IDEwMCU7CiAgbWF4LXdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9Ci5fMTl6cnZydDYgewogIG1hcmdpbi1sZWZ0OiA1MCU7CiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogIHdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDExNDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDExNSU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDE0NDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDEzMyU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLl8xOXpydnJ0NSB7CiAgICBtYXJnaW4tbGVmdDogNTAlOwogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogICAgd2lkdGg6IHZhcigtLXBhZ2UtY29udGVudC13aWR0aCk7CiAgfQp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA82UbY+aQBSFv/srbtM0gcZx0V2xxWSz/8QMcIXpzluGWVdt+O8NL66MAtZkk/aTgcOcezg811ilh6/aMGlhtgkFW7IigN8TAEFNxiSxSkewDPV+fb4XK2uVON0uJ/G1x7L2SFmhOT1EsOVYG2RUR5BwKrQX6P0UdtR4hLRn5v4UEsoTbx4E3+ABPEd98oHA3Pf9ykfTNGUyi1yDRa1RzjJJmEVRXOiP/mjaJlwVYCzhjXTwAAs3Yu14kbN9qpycEqzqBImSljKJhkgqMIJj+LhYxMHakexBYwRMciaRFOyIAy/VWFbNX9QQ1vkEk+SdpTaPoB7wrkxKYoP0NYL6h1R3KiU/6BxlEQF9s2pw2DN8j7bMFJYkOePpFUMBfGFCK2OptJ1++sSRCZz2DTgBOTSjXy8nLwJTRqFIDKIEKlPwOrWswh9679dz3NXoX46B9QAoOwbL1uBqMz5vN8a349Z+uHFXbdxBiq45GiPpiqXLaX0QjWM0ClKf/QVBNxm6RVEz4+VjPyHmKnkl1TVK6wAVnHDq8tBNN9NG/cLEktpjs2mskKNAaSOprDdjgmZYGWZoN5tC06SKVuRMTGG2YymqIRFFjGmv6E8H8rzxIUXx9kWq/vanF2wAcQpopJYVgPbBiuL1x/m6f45be8aiIxiW5Y5Stg3Ofx7NztjFOeHzOaOD0b9t9p6cTeP3nOj/EoJJ7/x/QSB80nt/OvKB/Hu67ezQ/1ytE/NvmnUOfH6x5aT8A2akKKpvCQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var spacingShim = '_6mi5is0';
export var layoutGap = 'var(--_6mi5is1)';
export var layoutPadding = 'var(--_6mi5is2)';
export var layoutAlignment = 'var(--_6mi5is3)';
export var layoutColumnCount = 'var(--_6mi5is4)';
export var layout = '_6mi5is5';
export var columnWidth = 'var(--_6mi5is6)';
export var layoutColumn = '_6mi5is7';
export var blockContentContainer = '_6mi5is8';