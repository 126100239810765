"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteTelemetry = exports.AddLineItemSource = exports.QuoteTelemetryHierarchy = void 0;
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var lodash_1 = require("lodash");
var QuoteTelemetryHierarchy;
(function (QuoteTelemetryHierarchy) {
    QuoteTelemetryHierarchy["Total"] = "totals";
    QuoteTelemetryHierarchy["Section"] = "section";
    QuoteTelemetryHierarchy["LineItem"] = "line-item";
    QuoteTelemetryHierarchy["PlanCard"] = "plan-card";
})(QuoteTelemetryHierarchy || (exports.QuoteTelemetryHierarchy = QuoteTelemetryHierarchy = {}));
var AddLineItemSource;
(function (AddLineItemSource) {
    AddLineItemSource["Button"] = "button";
    AddLineItemSource["Drag"] = "drag";
})(AddLineItemSource || (exports.AddLineItemSource = AddLineItemSource = {}));
var QuoteTelemetry = (function () {
    function QuoteTelemetry(_trackOnly, _debouncedTrack, telemetryContext, billingScheduleOrchestrator) {
        var _this = this;
        this._trackOnly = _trackOnly;
        this._debouncedTrack = _debouncedTrack;
        this.telemetryContext = telemetryContext;
        this.billingScheduleOrchestrator = billingScheduleOrchestrator;
        this.trackOnly = function (eventName, properties) {
            _this._trackOnly(eventName, properties);
        };
        this.debouncedTrack = function (eventName, properties) {
            _this._debouncedTrack(eventName, properties);
        };
        this.trackBillingScheduleChange = function (schedule) {
            _this.trackOnly("Quote Line Item Billing Schedule Changed", __assign({ type: schedule.type }, (schedule.type === Types_1.BillingScheduleType.Recurring ? { frequency: schedule.frequency } : {})));
        };
        this.trackRecommendedChange = function (isRecommended) {
            _this.trackOnly("Quote Block Pricing Plan Card Recommended ".concat(isRecommended ? "Enabled" : "Disabled"), {});
        };
        this.trackSavedLineItemInsertion = function (item) {
            if (!(0, Types_1.isPricedQuoteItem)(item)) {
                return;
            }
            _this.trackOnly("Quote Block Line Item Added", {
                quote_block_line_item_type: "saved line",
            });
            _this.trackBillingScheduleChange(_this.billingScheduleOrchestrator.getBillingSchedule(item));
        };
        this.trackAddSection = function (source, mode) {
            _this.trackOnly("Quote Block Section Added", {
                quote_block_section_added_source: source,
                quote_block_section_added_type: mode,
            });
        };
        this.getTelemetryItemType = function (type) {
            switch (type) {
                case Types_1.QuoteItemType.FixedCost: {
                    return "fixed cost";
                }
                case Types_1.QuoteItemType.Variable: {
                    return "variable";
                }
                case Types_1.QuoteItemType.Text: {
                    return "text";
                }
                case Types_1.QuoteItemType.Product: {
                    return "product";
                }
                default: {
                    throw new Error("Telemetry item type not available for quote item type ".concat(type));
                }
            }
        };
        this.getTelemetryItemName = function (displayMode) {
            if (displayMode === Types_1.QuoteSectionDisplayMode.PlanCards) {
                return "Pricing Plan Card";
            }
            return "Line Item";
        };
        this.trackAddLineItem = function (type, sectionDisplayMode, source, productTokenContext) {
            var item = _this.getTelemetryItemName(sectionDisplayMode);
            var properties = {
                quote_block_line_item_type: _this.getTelemetryItemType(type),
                quote_block_line_item_source: source.toString(),
            };
            if (productTokenContext) {
                _this.trackOnly("Quote Block ".concat(item, " Added"), __assign(__assign({}, properties), { quote_block_line_item_token_provider_type: productTokenContext.providerType, quote_block_line_item_token_object_type: productTokenContext.objectType }));
            }
            else {
                _this.trackOnly("Quote Block ".concat(item, " Added"), properties);
            }
        };
        this.trackDiscountApplied = function (context, type) {
            _this.debouncedTrack("Quote Block Discount Applied", {
                quote_block_discount_context: context,
                quote_block_discount_type: type,
            });
        };
        this.trackLineItemOptionalToggled = function (optional) {
            _this.trackOnly("Quote Block Line Item Optional Toggled", {
                quote_block_line_item_optional_state: optional ? "optional" : "mandatory",
            });
        };
        this.trackPlanCardSectionOptional = function (optional) {
            _this.trackOnly("Quote Block Pricing Plan Section Optional ".concat(optional ? "Enabled" : "Disabled"), {});
        };
        this.getTelemetrySelectionType = function (type) {
            if (type === Types_1.QuoteSelectionType.Multi) {
                return "multiple";
            }
            return type;
        };
        this.trackSectionSelectionToggled = function (type, selected) {
            _this.trackOnly("Quote Block Section Selection Toggled", {
                quote_block_section_toggle_context: _this.telemetryContext,
                quote_block_section_selection_type: _this.getTelemetrySelectionType(type),
                quote_block_section_selection_toggle_state: selected ? "selected" : "unselected",
            });
        };
        this.trackLineItemQuantityChanged = function (sectionDisplayMode) {
            _this.debouncedTrack("Quote Block Line Item Quantity Changed", {
                quote_block_line_item_quantity_changed_context: _this.telemetryContext,
            });
        };
        this.trackTaxExemptToggled = function (enabled, sectionDisplayMode) {
            var item = _this.getTelemetryItemName(sectionDisplayMode);
            _this.trackOnly("Quote Block ".concat(item, " Tax Exempt ").concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackOptionalQuantityToggled = function (enabled, sectionDisplayMode) {
            var item = _this.getTelemetryItemName(sectionDisplayMode);
            _this.trackOnly("Quote Block ".concat(item, " Optional Quantity ").concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackDeleteSection = function () {
            _this.trackOnly("Quote Block Section Deleted", {});
        };
        this.trackDeleteLineItem = function () {
            _this.trackOnly("Quote Block Line Item Deleted", {});
        };
        this.trackMoveSection = function () {
            _this.trackOnly("Quote Block Section Moved", {});
        };
        this.trackMoveLineItem = function () {
            _this.trackOnly("Quote Block Line Item Moved", {});
        };
        this.trackColumnVisibility = function (column, visible) {
            _this.trackOnly("Quote Block Column Visibility Toggled", {
                quote_block_visibility_toggle_state: visible ? "shown" : "hidden",
                quote_block_visibility_toggle_column_name: column,
            });
        };
        this.trackSectionOptionalChanged = function (enabled) {
            _this.trackOnly("Quote Block Pricing Plan Section Optional ".concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackItemSingleSelectChanged = function (enabled, sectionDisplayMode) {
            _this.trackOnly("Quote Block Pricing Plan Section Single Select ".concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackPlanCardSectionShowQuantityChanged = function (enabled) {
            _this.trackOnly("Quote Block Pricing Plan Section Show Quantity ".concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackPlanCardSectionShowFeaturesChanged = function (enabled) {
            _this.trackOnly("Quote Block Pricing Plan Section Show Features ".concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackPlanSectionAddFrequencyGroup = function (frequency) {
            _this.trackOnly("Quote Block Pricing Plan Section Add Frequency Group", {
                quote_line_item_billing_frequency: frequency,
            });
        };
        this.trackPlanSectionDeleteFrequencyGroup = function (frequency) {
            _this.trackOnly("Quote Block Pricing Plan Section Delete Frequency Group", {
                quote_line_item_billing_frequency: frequency,
            });
        };
        this.trackSectionShowSubtotalChanged = function (show) {
            _this.trackOnly("Quote Block Section Show Subtotal ".concat(show ? "Enabled" : "Disabled"), {});
        };
        this.trackPricingPlanSectionGrouping = function (enabled) {
            _this.trackOnly("Quote Block Pricing Plan Section Grouping ".concat(enabled ? "Enabled" : "Disabled"), {});
        };
        this.trackPricingPlanCardSelected = function () {
            _this.trackOnly("Quote Block Pricing Plan Card Selected", {
                plan_card_selected_location: _this.telemetryContext,
            });
        };
        this.trackProductLineItemFilterConfigured = function (name, operator) {
            _this.trackOnly("Product Line Item Filter Configured", __assign({ product_line_item_filter_name: name !== null && name !== void 0 ? name : "ALL_PRODUCTS" }, (!(0, lodash_1.isNil)(operator) ? { product_line_item_filter_operator: operator } : {})));
        };
    }
    return QuoteTelemetry;
}());
exports.QuoteTelemetry = QuoteTelemetry;
