"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Telemetry = void 0;
var lodash_1 = require("lodash");
var fingerprint_1 = require("@qwilr/fingerprint");
var Deferred_1 = require("@CommonFrontend/Utils/Deferred/Deferred");
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["None"] = 0] = "None";
    LogLevel[LogLevel["Info"] = 1] = "Info";
    LogLevel[LogLevel["Debug"] = 2] = "Debug";
})(LogLevel || (LogLevel = {}));
var DEBUG = LogLevel.None;
var options = {
    integrations: {
        All: false,
        "Amplitude (Actions)": true,
    },
};
var telemetryInstance;
var Telemetry = (function () {
    function Telemetry() {
        var _this = this;
        this.anonymousId = new Deferred_1.Deferred();
        this.telemetryMetadata = {};
        this.debouncedEvents = [];
        this.throttledEvents = [];
        this.track = function (eventName, eventParams, trackOptions) { return __awaiter(_this, void 0, Promise, function () {
            var parameters, authAttrs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.initializing];
                    case 1:
                        _a.sent();
                        parameters = __assign(__assign({}, this.telemetryMetadata), eventParams);
                        if (!!!(trackOptions === null || trackOptions === void 0 ? void 0 : trackOptions.includeAuthAttrs)) return [3, 3];
                        return [4, this.getAuthAttrs()];
                    case 2:
                        authAttrs = _a.sent();
                        Object.assign(parameters, authAttrs);
                        _a.label = 3;
                    case 3:
                        if (DEBUG) {
                            console.log("Sending event: ".concat(eventName), parameters);
                        }
                        if (this.analytics) {
                            this.analytics.track(eventName, parameters, options);
                        }
                        return [2];
                }
            });
        }); };
        this.debouncedTrack = function (eventName, eventParams, debounceDelay, debounceOptions) {
            if (debounceDelay === void 0) { debounceDelay = 200; }
            if (debounceOptions === void 0) { debounceOptions = { trailing: true }; }
            var existing = (0, lodash_1.find)(_this.debouncedEvents, function (x) { return x.eventName === eventName; });
            if (existing) {
                existing.debounced(existing.eventName, eventParams);
            }
            else {
                var debouncedTrackFn = (0, lodash_1.debounce)(_this.track, debounceDelay, debounceOptions);
                debouncedTrackFn(eventName, eventParams);
                _this.debouncedEvents.push({
                    eventName: eventName,
                    debounced: debouncedTrackFn,
                });
            }
        };
        this.throttledTrack = function (eventName, eventParams, throttleDelay, throttleOptions) {
            if (throttleDelay === void 0) { throttleDelay = 1000; }
            if (throttleOptions === void 0) { throttleOptions = { leading: true, trailing: false }; }
            var existing = (0, lodash_1.find)(_this.throttledEvents, function (x) { return x.eventName === eventName; });
            if (existing) {
                existing.throttled(existing.eventName, eventParams);
            }
            else {
                var throttledTrackFn = (0, lodash_1.throttle)(_this.track, throttleDelay, throttleOptions);
                throttledTrackFn(eventName, eventParams);
                _this.throttledEvents.push({
                    eventName: eventName,
                    throttled: throttledTrackFn,
                });
            }
        };
        var isClientSide = typeof window !== "undefined" && !!window.analytics;
        if (isClientSide) {
            this.initialize();
        }
        else {
            this.initializing = Promise.resolve();
        }
    }
    Telemetry.getInstance = function () {
        if (!telemetryInstance) {
            telemetryInstance = new Telemetry();
        }
        return telemetryInstance;
    };
    Telemetry.prototype.initialize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.initializing = new Promise(function (resolve) {
                    window.analytics.ready(function () { return __awaiter(_this, void 0, void 0, function () {
                        var analytics, fingerprintId;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    analytics = window.analytics;
                                    this.analytics = analytics;
                                    return [4, (0, fingerprint_1.getFingerprintId)(process.env.FINGERPRINT_PUBLIC_KEY)];
                                case 1:
                                    fingerprintId = _a.sent();
                                    if (fingerprintId) {
                                        window.analytics.user().anonymousId(fingerprintId);
                                    }
                                    this.anonymousId.resolve(window.analytics.user().anonymousId());
                                    try {
                                        this.telemetryMetadata = JSON.parse(window.telemetryMetadata);
                                    }
                                    catch (err) {
                                        console.warn("Unable to retrieve telemetryMetadata from the window", err);
                                    }
                                    if (DEBUG === LogLevel.Debug) {
                                        window.analytics.debug();
                                    }
                                    else {
                                        window.analytics.debug(false);
                                    }
                                    resolve();
                                    return [2];
                            }
                        });
                    }); });
                });
                return [2];
            });
        });
    };
    Telemetry.prototype.getIsAuth = function () {
        var _a;
        return !!((_a = this.telemetryMetadata) === null || _a === void 0 ? void 0 : _a.auth_user_id);
    };
    Telemetry.prototype.getAnonymousId = function () {
        return __awaiter(this, void 0, Promise, function () {
            var error_1;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!window.analytics || !window.analytics.user) {
                            return [2, ""];
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, , 7]);
                        if (!Promise.race) return [3, 3];
                        return [4, Promise.race([
                                this.anonymousId.promise,
                                new Promise(function (resolve, reject) {
                                    setTimeout(function () { return reject("anonymousId timed out"); }, 6000);
                                }),
                            ])];
                    case 2:
                        _d.sent();
                        return [3, 5];
                    case 3: return [4, this.anonymousId.promise];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5: return [2, (_c = (_b = (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.call(_a).anonymousId()) !== null && _c !== void 0 ? _c : ""];
                    case 6:
                        error_1 = _d.sent();
                        return [2, ""];
                    case 7: return [2];
                }
            });
        });
    };
    Telemetry.prototype.getAuthAttrs = function () {
        return __awaiter(this, void 0, Promise, function () {
            var anonymousId;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.anonymousId.promise];
                    case 1:
                        anonymousId = _c.sent();
                        return [2, {
                                anon_user_id: anonymousId,
                                has_anon_id: Boolean(anonymousId),
                                auth_user_id: (_a = this.telemetryMetadata) === null || _a === void 0 ? void 0 : _a.auth_user_id,
                                is_auth: Boolean((_b = this.telemetryMetadata) === null || _b === void 0 ? void 0 : _b.auth_user_id),
                            }];
                }
            });
        });
    };
    return Telemetry;
}());
exports.Telemetry = Telemetry;
