import "../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUwY7bIBC95yvooVJzILKz6SYlt/7CHnqMxjC2Z4uBAt44qfrvlXHXdpJtlbbyATHMG948v2HldrIEm7HvC8acDRTJGsE8aoj0gvvFj8VqSMlTirTaesFewH/gPG04SWueWikxhMMhL/T2+TmPyxlynZBHUrEWbJe5br9grEaq6jjtC+sVeu5BURsEy7PsfR9WFJyGk2CVJ9UHnAaJnCI2QTCJJqJPcJBfK29bo/gbFEPrS5A40quWw5UdDzUoexSMTMDIsvTlrrtAO08N+NPnxHCskacaoKkyPKAuJzZj4w+p8dKayAOdUbB85zr2jhpnfQQT96+nx19iPGbZxfFYaZMqjWKUGpNo/coVeZTDb5NWt42Z4T4mXAXuVY/gQJKpeNd1emrl2/x3Pf4OMwOYOWD7D5fs/vaST1cOhSJY3UbsZdBYRsGyWTb8IdsPWs/TN6Ncq4hd5GRcGw+HtKRKV+7MJsdebHhhY7SNYGvXsWA1qQsj1QiKTDV2iMlCDlQf5WQ0GbyfmBBpFmqrFfrE0vYaxtNdJTQUqG/8mYbxBrodnfHfLLZ3sBgk6wNPdEaecma+WHfLm8GZIF9ShDeoqG1GzMMwrW88DT2bMW2zvJrOxDV6MKG0vhFMgqMIms79y/gTrevp8DwFAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW7W7jNhD8n6fYIigQt6Ur+SOOFaAocIc+QO8BDIpc2XQoUkeuEvuKvHshypZFWecGvV8CyJ3V7uzsSNNNekxd/bVK4Z87gJyLl62ztZFMWG1dBm87RfjcXFkn0THHpap9Bq/cPTDWHv4dzpiwhpzVm02a69V+n6bbyQWYQVodwFutJNw/pXyWzxbJ89373fRcwayt4Dr68ypdz9dR7DzE7lBtd5SB4Fo8tAWdaviiviEru0pmyQR+hTSUU3EpldkyjQWd2/AVF83ZwV+KfwnRUvlK82MGhcZDc8C12hqmCEufgSeHJHbN+b72pIpjKAANZdDkRJYjvSGaHoG5JbLlGB0yajGFKfHch0a3vMogGbvebJSRSnCy7jsDvE+Sp+UqHwcTz5lWnpgyBl1EapokP482JtAQuijfIiBPzF6R2md1P4mAyxZovSJlTQY891bXreCU8UjDth8HAIeak3oNAO4rFMQcJ2UzmE3na/gd0gi+CvA4cD5N51eB/EOF3ei5twZVrKStU7JP7LWWIo2hiXWRx1zH9Ihw+aYk7S4TPK9U8hEVPiUjy36z1WhnIGmDbU1aGczAWNPaRyfMy1lhDbGCl0ofM/jMS2XNbyBq508DDfdefcMM5osqrN9Z1LPF7NPAE0RWWFH7not0K7BYLedLKcQgPqs0F7izWp60/wMFyQJXmMNPqqysI24osNBwRMdsIC7Zr/E8iVk3ibOTaevYDnlDekcxBi2dd/Rx1hTRy4whcye1lujeffHfso6ne/HQtIzcvClXcr/DyM5/4DNBeCAWlH+xmBH/bZ5MKoei7UBYXZfmplENFupyHGz1Zr896rZjwkqT9fqvT8/jxttcFmI+j9Ls4A/4JaQqudsq080/XmQ1tsixNfei9yG6U1u7rlY1fTJ8RUN+RAv6/4DKGBTr2vStqWts6Ix9w1Axx/aGu5O9GtZ1rkYfllMGzde9v57nf4jRQclw2QzqI/r1tSu4wMuLd+HFp3G6dkQf/wRWPTGcptA4J+uGPZ2F+OpJFNwuTs9H6BIk0BvA9EWzZpMG6Yc09N7/NQQwxoXAigL4TEzfiJrzrodF3AL139GCrrLFiLov7/XQxerQhkeNgjYb1FiiIcbQyME/zohAY6wyVU3Rf+UZ8GeJUnHwwiEa4EbCQ8kP7FTSYplUh8kJKI+ZsfRwXzllaAKDfwSAJIPaSCyUwfBhf7+JEt9BAaRXed7/BZdAsUafCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var signatureContainer = '_1y1ruqp0';
export var card = _7a468({defaultClassName:'_1y1ruqp1',variantClassNames:{invalid:{true:'_1y1ruqp2'}},defaultVariants:{},compoundVariants:[]});
export var cardHeader = '_1y1ruqp3';
export var actions = '_1y1ruqp4';
export var tabPanel = _7a468({defaultClassName:'_1y1ruqp6',variantClassNames:{mode:{type:'_1y1ruqp7',draw:'_1y1ruqp8',upload:'_1y1ruqp9'}},defaultVariants:{},compoundVariants:[]});
export var inputWrapper = _7a468({defaultClassName:'_1y1ruqpa',variantClassNames:{isSimpleMode:{true:'_1y1ruqpb'}},defaultVariants:{},compoundVariants:[]});
export var input = _7a468({defaultClassName:'_1y1ruqpc',variantClassNames:{isSimpleMode:{true:'_1y1ruqpd'}},defaultVariants:{},compoundVariants:[]});
export var uploadWrapper = _7a468({defaultClassName:'_1y1ruqp5',variantClassNames:{hasUploaded:{true:'_1y1ruqpe'}},defaultVariants:{},compoundVariants:[]});
export var upload = _7a468({defaultClassName:'_1y1ruqpf',variantClassNames:{draggedOver:{true:'_1y1ruqpg'}},defaultVariants:{},compoundVariants:[]});
export var uploadDescription = '_1y1ruqph';
export var canvas = _7a468({defaultClassName:'_1y1ruqpi _1y1ruqp5',variantClassNames:{mode:{type:'_1y1ruqpj',draw:'_1y1ruqpk',upload:'_1y1ruqpl'},hasUploaded:{true:'_1y1ruqpm'}},defaultVariants:{},compoundVariants:[]});
export var error = '_1y1ruqpn';
export var errorIcon = '_1y1ruqpo';
export var errorText = '_1y1ruqpp';
export var simpleModeLabel = '_1y1ruqpq';
export var optionMenuItem = _7a468({defaultClassName:'',variantClassNames:{isSimpleMode:{true:'_1y1ruqpr',false:'_1y1ruqps'}},defaultVariants:{},compoundVariants:[]});
export var label = '_1y1ruqpt';
export var select = '_1y1ruqpu';