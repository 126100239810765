"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHtmlFromText = void 0;
var URL_1 = require("@CommonFrontendBackend/Utils/URL");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var lodash_1 = require("lodash");
var createHtmlFromText = function (token, options) {
    var startWrap = "";
    var endWrap = "";
    if (token.href != null) {
        var style = "";
        if (options.computeForegroundColor) {
            var linkColor = options.blockStyles
                ? options.blockStyles["link"].color
                : undefined;
            var decoratedLinkColor = linkColor
                ? options.colorProvider.decorateColor((0, GetBlockStyle_1.convertColorObjToColor)(linkColor)).value
                : "#000000";
            var computedColor = options.computeForegroundColor(decoratedLinkColor);
            style = " style=\"color:".concat(computedColor, ";border-bottom-color:").concat(computedColor, ";\"");
        }
        var href = (0, URL_1.isURL)(token.href, true) ? token.href : "#";
        startWrap += "<a href=\"".concat(href, "\" target=\"_blank\"").concat(style, ">");
        endWrap = "</a>".concat(endWrap);
    }
    if (token.style) {
        var styleWrapStart = "<span style='";
        var classWrapStart = "class='";
        var addClassToHtml = false;
        if (token.style.bold) {
            styleWrapStart = "".concat(styleWrapStart, "font-weight: bold; ");
        }
        if (token.style.italic) {
            styleWrapStart = "".concat(styleWrapStart, "font-style: italic; ");
        }
        if (token.style.strikethrough) {
            styleWrapStart = "".concat(styleWrapStart, "text-decoration: line-through;");
            addClassToHtml = true;
            classWrapStart = "".concat(classWrapStart, "strikethrough");
        }
        if (token.style.color) {
            styleWrapStart = "".concat(styleWrapStart, "color: ").concat(options.colorProvider.decorateColor((0, GetBlockStyle_1.convertColorObjToColor)(token["style"].color)).value, "; ");
        }
        var classWrapEnd = "'";
        if (addClassToHtml) {
            startWrap += "".concat(styleWrapStart, "'").concat(classWrapStart).concat(classWrapEnd, ">");
        }
        else {
            startWrap += "".concat(styleWrapStart, "'>");
        }
        endWrap = "</span>".concat(endWrap);
    }
    var textContent = sanitiseContent(token.content);
    return "".concat(startWrap).concat(textContent).concat(endWrap);
};
exports.createHtmlFromText = createHtmlFromText;
var sanitiseContent = function (content) {
    var escapedContent = (0, lodash_1.escape)(content);
    var escapedAndStrippedContent = escapedContent.replace(/\r/g, "");
    return escapedAndStrippedContent;
};
